


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { SelectItem } from '@/classes/base/elements';
@Component({})
export default class BaseSelect extends Vue {
	@Prop({ default: null })
	selectItem: SelectItem;

	@Prop({ default: false })
	darkMode: boolean;

	public displaySettingsList = false;

	async openPanel(): Promise<void> {
		if (this.displaySettingsList === false) {
			this.displaySettingsList = true;
			setTimeout(() => {
				const list = document.getElementById('listbox');
				if (list) {
					list.focus();
				}
			}, 1);
		} else if (this.displaySettingsList === true) {
			this.displaySettingsList = false;
		}
	}

	async closePanel(): Promise<void> {
		setTimeout(() => {
			this.displaySettingsList = false;
		}, 110);
	}
}
